import React from "react";
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ShowButton,
    TagField,
    EmailField,
    DateField,
    SaveButton, ImportButton, useImport, CreateButton,
} from "@refinedev/antd";
import {Table, Space, Form, Input, Button, Card} from "antd";
import { SearchOutlined } from "@ant-design/icons";

interface ISearch {
  firstName: string;
}
export const ClientList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable({
    syncWithLocation: true,
    onSearch: (values:ISearch) => {
      return [
      {
        field: "firstName",
        operator: "contains",
        value: values.firstName,
      },
    ]}
  });
  // const importProps = useImport({
  //   mapData: (data: any) => {
  //     return data.map((item: any) => {
  //       return {
  //         firstName: item["First Name"],
  //         lastName: item["Last Name"],
  //         email: item["EmailID"],
  //         phone: item["Phone"] || item["MobilePhone"],
  //       };
  //     });
  //   }
  // });

  return (
    <List>
        <Space>
        <Form {...searchFormProps} layout="inline" >
          <Form.Item name="firstName">
            <Input placeholder="Search By Name" prefix={<SearchOutlined />} />
          </Form.Item>
          <SaveButton onClick={searchFormProps.form?.submit} />
        </Form>

        </Space>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="Id" />
          <Table.Column dataIndex="firstName" title="First Name" />
          <Table.Column dataIndex="lastName" title="Last Name" />
          <Table.Column
            dataIndex={["email"]}
            title="Email"
            render={(value: any) => <EmailField value={value} />}
          />
          <Table.Column dataIndex="phone" title="Phone" />

          <Table.Column
            dataIndex={["updatedAt"]}
            title="Last Updated"
            render={(value: any) => <DateField value={value} />}
          />

          <Table.Column
            title="Actions"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                />
                <ShowButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                />
                  <CreateButton
                    size="small"
                    resource="measurements"
                  >Add Measurement</CreateButton>

              </Space>
            )}
          />
        </Table>

    </List>
  );
};
