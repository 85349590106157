import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TagField,
    TextField,
    DateField,
} from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const ProjectShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>Id</Title>
            <NumberField value={record?.id ?? ""} />
            <Title level={5}>Name</Title>
            <TextField value={record?.Name} />
            <Title level={5}>Description</Title>
            <TextField value={record?.description} />
            <Title level={5}>Delivery Date</Title>
            <DateField value={record?.deliveryDate} />
            <Title level={5}>Created At</Title>
            <DateField value={record?.createdAt} />
            <Title level={5}>Updated At</Title>
            <DateField value={record?.updatedAt} />
            <Title level={5}>Published At</Title>
            <DateField value={record?.publishedAt} />
        </Show>
    );
};
