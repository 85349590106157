import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import {Edit, useForm, useSelect} from "@refinedev/antd";
import {Col, Form, Input, Row, Select, Space} from "antd";
import dayjs from "dayjs";

export const MeasurementEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm({
    meta: {
      populate: "*"
    }
  });

  const measurementsData = queryResult?.data?.data;
  const {selectProps} = useSelect({
    resource: "clients",
    optionLabel: "firstName",
    optionValue: "id",
  });

  const formStyle = {
    maxWidth: 'none',

    padding: 24,
  };
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form wrapperCol={ {span:15}} style={formStyle} {...formProps} layout="vertical">
            <Row>
              <Col span={8}>
                <Form.Item
                  label="Id"
                  name={["id"]}
                  rules={[

                  ]}
                >
                  <Input readOnly disabled />
                </Form.Item>
                <Form.Item
                  label="Client"
                  name={["client","id"]}
                  rules={[
                    {required: true}
                  ]}
                >
                  <Select
                    placeholder="Select Client Name"
                    {...selectProps} >

                  </Select>
                </Form.Item>
                <Form.Item
                  label="Length"
                  name={["length"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Upper Chest"
                  name={["upperChest"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Bust"
                  name={["bust"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Waist"
                  name={["waist"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Stomach"
                  name={["stomach"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Low Waist"
                  name={["lowWaist"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Hips"
                  name={["hips"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Cross Front"
                  name={["crossFront"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                    label="Slit"
                    name={["slit"]}
                    rules={[

                    ]}
                >
                  <Input />
                </Form.Item>

              </Col>
              <Col span={8}>
                <Form.Item
                  label="Cross Back"
                  name={["crossBack"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Shoulder"
                  name={["shoulder"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Arm Hole"
                  name={["armHole"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Sleeves Length"
                  name={["sleevesLength"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Sleeves Round"
                  name={["sleevesRound"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Neck Front Back"
                  name={["neckFrontBack"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Bottom Length"
                  name={["bottomLength"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Waist To Knee Length"
                  name={["waistToKneeLength"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Upper Thighs"
                  name={["upperThighs"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Lower Thighs"
                  name={["lowerThighs"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>

              </Col>
              <Col span={8}>
                <Form.Item
                  label="Knees"
                  name={["knees"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Calf"
                  name={["calf"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Ankle"
                  name={["ankle"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Blouse Length"
                  name={["blouseLength"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Dart Point"
                  name={["dartPoint"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Gown Length"
                  name={["gownLength"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Peticot Length"
                  name={["peticotLength"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Skirt Length Full"
                  name={["skirtLengthFull"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Belt Round"
                  name={["beltRound"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Yoke"
                  name={["yoke"]}
                  rules={[

                  ]}
                >
                  <Input />
                </Form.Item>

              </Col>
            </Row>
      </Form>
    </Edit>
  );
};
