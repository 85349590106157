import React , {useState} from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import {
  Show,
  NumberField,
  TagField,
  TextField,
  EmailField,
  DateField,
} from "@refinedev/antd";
import { Typography } from "antd";
import FemaleSilhouette from "../../components/FemaleSilhouette/FemaleSilhouette";
interface BodyMeasurements {
  bust: number;
  waist: number;
  hips: number;
}

const { Title } = Typography;

export const ClientShow: React.FC<IResourceComponentsProps> = () => {

  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ""} />
      <Title level={5}>First Name</Title>
      <TextField value={record?.firstName} />
      <Title level={5}>Last Name</Title>
      <TextField value={record?.lastName} />
      <Title level={5}>Email</Title>
      <EmailField value={record?.email} />
      <Title level={5}>Phone</Title>
      <NumberField value={record?.phone ?? ""} />
      <Title level={5}>Created At</Title>
      <DateField value={record?.createdAt} />
      <Title level={5}>Updated At</Title>
      <DateField value={record?.updatedAt} />
      <Title level={5}>Published At</Title>
      <DateField value={record?.publishedAt} />
    </Show>
  );
};
