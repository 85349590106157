import React, {useState} from "react";
import {IResourceComponentsProps, usePermissions, useShow} from "@refinedev/core";
import {Show, NumberField, DateField, TextField} from "@refinedev/antd";
import {Col, FloatButton, Row, Typography} from "antd";
import FemaleSilhouette from "../../components/FemaleSilhouette/FemaleSilhouette";
import {RWebShare} from "react-web-share";
import {ShareAltOutlined} from "@ant-design/icons";

const { Title } = Typography;
interface BodyMeasurements {
  bust: number;
  waist: number;
  hips: number;
}

export const MeasurementShow: React.FC<IResourceComponentsProps> = () => {
  const { data: permissionsData } = usePermissions();

  const { queryResult } = useShow({
    meta: {
      populate: "*"
    },
  });
  const [measurements, setMeasurements] = useState<BodyMeasurements>({
    bust: 36,
    waist: 28,
    hips: 38,
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  // console.dir(record);
  return (

  <Show
      canDelete={permissionsData as boolean}
      canEdit={permissionsData as boolean}
      isLoading={isLoading}>
    {/*<FemaleSilhouette measurements={measurements} />*/}


    <Row gutter={12}>
        <Col span={12}>
          <Title level={5}>Client Name</Title>
          <TextField value={record?.client?.firstName +" " +  record?.client?.lastName ?? ""} />
          <Title level={5}>Length</Title>
          <TextField value={record?.length ?? ""} />
          <Title level={5}>Upper Chest</Title>
          <TextField value={record?.upperChest ?? ""} />
          <Title level={5}>Bust</Title>
          <TextField value={record?.bust ?? ""} />
          <Title level={5}>Waist</Title>
          <TextField value={record?.waist ?? ""} />
          <Title level={5}>Stomach</Title>
          <TextField value={record?.stomach ?? ""} />
          <Title level={5}>Low Waist</Title>
          <TextField value={record?.lowWaist ?? ""} />
          <Title level={5}>Hips</Title>
          <TextField value={record?.hips ?? ""} />
          <Title level={5}>Cross Front</Title>
          <TextField value={record?.crossFront ?? ""} />
          <Title level={5}>Cross Back</Title>
          <TextField value={record?.crossBack ?? ""} />
          <Title level={5}>Calf</Title>
          <TextField value={record?.calf ?? ""} />

        </Col>
        <Col span={12}>
          <Title level={5}>Client ID</Title>
          <TextField value={record?.client?.id ?? ""} />
          <Title level={5}>Shoulder</Title>
          <TextField value={record?.shoulder ?? ""} />
          <Title level={5}>Arm Hole</Title>
          <TextField value={record?.armHole ?? ""} />
          <Title level={5}>Sleeves Length</Title>
          <TextField value={record?.sleevesLength ?? ""} />
          <Title level={5}>Sleeves Round</Title>
          <TextField value={record?.sleevesRound ?? ""} />
          <Title level={5}>Neck Front Back</Title>
          <TextField value={record?.neckFrontBack ?? ""} />
          <Title level={5}>Bottom Length</Title>
          <TextField value={record?.bottomLength ?? ""} />
          <Title level={5}>Waist To Knee Length</Title>
          <TextField value={record?.waistToKneeLength ?? ""} />
          <Title level={5}>Upper Thighs</Title>
          <TextField value={record?.upperThighs ?? ""} />
          <Title level={5}>Lower Thighs</Title>
          <TextField value={record?.lowerThighs ?? ""} />
          <Title level={5}>Knees</Title>
          <TextField value={record?.knees ?? ""} />


        </Col>
        <Col span={12}>


          <Title level={5}>Ankle</Title>
          <TextField value={record?.ankle ?? ""} />
          <Title level={5}>Blouse Length</Title>
          <TextField value={record?.blouseLength ?? ""} />
          <Title level={5}>Dart Point</Title>
          <TextField value={record?.dartPoint ?? ""} />
          <Title level={5}>Gown Length</Title>
          <TextField value={record?.gownLength ?? ""} />

          <Title level={5}>Peticot Length</Title>
          <TextField value={record?.peticotLength ?? ""} />
          <Title level={5}>Skirt Length Full</Title>
          <TextField value={record?.skirtLengthFull ?? ""} />
          <Title level={5}>Belt Round</Title>
          <TextField value={record?.beltRound ?? ""} />
          <Title level={5}>Yoke</Title>
          <TextField value={record?.yoke ?? ""} />
          <Title level={5}>Slit</Title>
          <TextField value={record?.slit ?? ""} />
          <Title level={5}>Updated At</Title>
          <DateField value={record?.updatedAt} />
        </Col>
      </Row>

    <RWebShare
        data={{
          text: "Measurements for " + record?.client?.firstName +" " +  record?.client?.lastName ?? "",
          url: window.location.href,
          title: "Client Measurement - " + record?.client?.firstName +" " +  record?.client?.lastName ?? "",
      }}
        onClick={() => console.log("shared successfully!")}
    >
      <FloatButton type="primary" icon={<ShareAltOutlined/>}></FloatButton>
    </RWebShare>
    </Show>
  );
};
