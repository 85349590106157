import {Refine, Authenticated, CanAccess} from '@refinedev/core';
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import {
  AuthPage,
  notificationProvider,
  Layout,
  ErrorComponent
} from '@refinedev/antd';
import "@refinedev/antd/dist/reset.css";
import "./pages/global.css"

import { DataProvider } from "@refinedev/strapi-v4";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import routerBindings, { NavigateToResource, CatchAllNavigate, UnsavedChangesNotifier } from "@refinedev/react-router-v6";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { Header } from "./components/header";
import { authProvider, axiosInstance } from "./authProvider";
import { API_URL } from "./constants";
import {MeasurementList } from "pages/measurements/list";
import {MeasurementEdit } from "pages/measurements/edit";
import {MeasurementCreate } from "pages/measurements/create";
import {ClientList } from "pages/clients/list";
import {ClientEdit } from "pages/clients/edit";
import {ClientCreate } from "pages/clients/create";
import {ClientShow } from "pages/clients/show";
import {MeasurementShow} from "./pages/measurements/show";
import {ProjectCreate, ProjectEdit, ProjectList, ProjectShow} from "./pages/projects";


function App() {




    return (
        <BrowserRouter>
        <RefineKbarProvider>
            <ColorModeContextProvider>
            <Refine authProvider={authProvider}
dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
notificationProvider={notificationProvider}
routerProvider={routerBindings}
                    resources={[{
                      name: "clients",
                      list: "/clients",
                      create: "/clients/create",
                      edit: "/clients/edit/:id",
                      show: "/clients/show/:id",
                      meta: {
                        canDelete: true,
                      },
                    }, {
                      name: "measurements",
                      list: "/measurements",
                      create: "/measurements/create",
                      edit: "/measurements/edit/:id",
                      show: "/measurements/show/:id",
                      meta: {
                        canDelete: true,
                      },
                    }, {
                        /** 
                         *
                         * Resource is default with default paths, you need to add the components to the paths accordingly.
                         * You can also add custom paths to the resource.
                         * 
                         * Use `<ProjectList/>` component at `/project` path.
                         * Use `<ProjectCreate/>` component at `/project/create` path.
                         * Use `<ProjectEdit/>` component at `/project/edit/:id` path.
                         * Use `<ProjectShow/>` component at `/project/show/:id` path.
                         *
                         **/
                        name: "projects",

                        list: "/project",
                        create: "/project/create",
                        edit: "/project/edit/:id",
                        show: "/project/show/:id"
                    }]}
                options={{
                    syncWithLocation: true,
                    warnWhenUnsavedChanges: true,
                }}
            >
                <Routes>
                    <Route

                        >
                        <Route index element={
                                <NavigateToResource resource="measurements" />
                        } />
                        <Route path="/measurements"  element={
                            <Layout Header={Header}>
                                <Outlet />
                            </Layout>
                        }>
                          <Route index element={<MeasurementList />} />
                          <Route path="create" element={
                              <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                                  <MeasurementCreate />
                              </Authenticated>

                          } />
                          <Route path="edit/:id" element={
                              <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                                  <MeasurementEdit  />
                              </Authenticated>
                          } />
                          <Route path="show/:id" element={<MeasurementShow />} />
                        </Route>
                      <Route
                          element={
                              <Authenticated
                                  fallback={<CatchAllNavigate to="/login" />}
                              >
                                  <Layout Header={Header}>
                                      <Outlet />
                                  </Layout>
                              </Authenticated>
                          }
                          path="/clients">
                        <Route index element={<ClientList />} />
                        <Route path="create" element={<ClientCreate />} />
                        <Route path="edit/:id" element={<ClientEdit />} />
                        <Route path="show/:id" element={<ClientShow />} />
                      </Route>
                        <Route
                            element={
                                <Authenticated
                                    fallback={<CatchAllNavigate to="/login" />}
                                >
                                    <Layout Header={Header}>
                                        <Outlet />
                                    </Layout>
                                </Authenticated>
                            }
                            path="/project">
                            <Route index element={<ProjectList />} />
                            <Route path="create" element={<ProjectCreate />} />
                            <Route path="edit/:id" element={<ProjectEdit />} />
                            <Route path="show/:id" element={<ProjectShow />} />
                        </Route>

                    </Route>
                    <Route
                        element={
                            <Authenticated fallback={<Outlet />}>
                                <NavigateToResource />
                            </Authenticated>
                        }
                    >
                            <Route path="/login" element={<AuthPage type="login" formProps={{ initialValues:{ email: "", password: "" } }} />} />
                    </Route>
                    <Route
                        element={
                            <Authenticated>
                                    <Layout Header={Header}>
                                        <Outlet />
                                    </Layout>
                            </Authenticated>
                        }
                    >
                        <Route path="*" element={<ErrorComponent />} />
                    </Route>
                </Routes>


                <RefineKbar />
                <UnsavedChangesNotifier />
            </Refine>
            </ColorModeContextProvider>
        </RefineKbarProvider>
        </BrowserRouter>
    );
};

export default App;
