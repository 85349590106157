import React from "react";
import {IResourceComponentsProps, BaseRecord, useMany, useNavigation} from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ShowButton,
    DateField, SaveButton,
} from "@refinedev/antd";
import {Table, Space, Select, Form, Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";

export const MeasurementList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<any>({
    syncWithLocation: true,
    onSearch: (values: any) => {
        return [
            {
                field: "client.firstName",
                operator: "contains",
                value: values.firstName,
            },
        ]
    },
    meta: {
      populate: "*"
    },

  });
  const {data:clientData, isLoading:clientIsLoading} = useMany({
    resource: "clients",
    ids: tableProps?.dataSource?.map((item) =>  {
        return item?.client?.id;
    })?? [],
    queryOptions: {
      enabled: !!tableProps.dataSource?.length,
    }
  });

  return (
    <List>
        <Space>
            <Form {...searchFormProps} layout="inline" >
                <Form.Item name="firstName">
                    <Input placeholder="Search By Name" prefix={<SearchOutlined />} />
                </Form.Item>
                <SaveButton onClick={searchFormProps.form?.submit} >Search </SaveButton>
            </Form>

        </Space>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column dataIndex={["client", "id"]}
                      title="Client Name"
                      render={(value: any, record: BaseRecord) => clientIsLoading?(
                        <>Loading...</>
                        ):(
                          clientData?.data.find((item) => item.id === value)?.firstName
                          + " "
                          + clientData?.data.find((item) => item.id === value)?.lastName
                        )
                      }
        />

        <Table.Column
          dataIndex={["createdAt"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />

        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
              />

            </Space>
          )}
        />
      </Table>
    </List>
  );
};
